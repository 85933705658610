import React from "react";
import ReactHtmlParser from "react-html-parser";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = ({ data }) => {
  const [lang, setLang] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );
  const privacy = data[lang === "gr" ? "allPagesGr" : "allPagesEn"].edges.find(
    pri => pri.node.label === "privacy"
  );
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="container">
        <div style={{ marginTop: "50px" }}> </div>
        <article>{ReactHtmlParser(privacy.node.value)}</article>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
export const query = graphql`
  query {
    allPagesGr {
      edges {
        node {
          label
          value
        }
      }
    }
    allPagesEn {
      edges {
        node {
          label
          value
        }
      }
    }
  }
`;
